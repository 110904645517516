import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>Error 404 - MK</title>
      </Helmet>
      <Header />
      <h2>404 - Page Not Found</h2>
      <p>The requested page does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';

function StockPage() {
  return (
    <div>
      <Helmet>
        <title>Stock - MK</title>
      </Helmet>
      <Header />
      <div className="h-[100px] bg-cyan-50 flex flex-col items-center justify-center text-cyan-950">
        <p className='pt-7 pb-4 font-semibold'>Stock</p>
      </div>
    </div>
  );
}

export default StockPage;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  

  return (
    <header className="bg-cyan-950 p-5 h-16 sticky top-0 shadow">
      <div className='max-w-screen-xl mx-auto flex justify-between h-full'>
        <div className="flex items-center">
          <Link to="/" className=''>
            <img src="/logo-white.png" alt="Logo" className="h-7 opacity-80 align-middle"/>
          </Link>
        </div>
        <nav className='text-cyan-50 font-poppins font-normal flex items-center'>
          <ul className="hidden md:flex space-x-6">
            <li>
              <Link to="/" className={`${isActive('/') ? 'underline' : ''} hover:underline decoration-cyan-50 decoration-2 underline-offset-4`}>Home</Link>
            </li>
            <li>
              <Link to="/about" className={`${isActive('/about') ? 'underline' : ''} hover:underline decoration-cyan-50 decoration-2 underline-offset-4`}>About</Link>
            </li>
            <li>
              <Link to="/contact" className={`${isActive('/contact') ? 'underline' : ''} hover:underline decoration-cyan-50 decoration-2 underline-offset-4`}>Contact</Link>
            </li>
            <li>
              <Link to="/stock" className={`${isActive('/stock') ? 'underline' : ''} hover:underline decoration-cyan-50 decoration-2 underline-offset-4`}>Stock</Link>
            </li>
            <li>
              <Link to="/work" className={`${isActive('/work') ? 'underline' : ''} hover:underline decoration-cyan-50 decoration-2 underline-offset-4`}>Work</Link>
            </li>
          </ul>

          <div className="md:hidden">
            <button
              type="button"
              className="text-cyan-50 p-2 border-2 rounded-md"
              onClick={handleMenuToggle}
            >
              {isMenuOpen ? <FaTimes className=''/> : <FaBars className=''/>}
            </button>
          </div>

          {isMenuOpen && (
            <ul className="md:hidden absolute top-16 bg-cyan-100 left-0 right-0 text-cyan-950 divide-y-2 divide-white/40">
              <li>
                <Link to="/"  className={`${isActive('/') ? 'underline' : ''} hover:underline decoration-cyan-950 decoration-2 underline-offset-4 font-medium flex justify-center py-3`}>Home</Link>
              </li>
              <li>
                <Link to="/about"  className={`${isActive('/about') ? 'underline' : ''} hover:underline decoration-cyan-950 decoration-2 underline-offset-4 font-medium flex justify-center py-3`}>About</Link>
              </li>
              <li>
                <Link to="/contact"  className={`${isActive('/contact') ? 'underline' : ''} hover:underline decoration-cyan-950 decoration-2 underline-offset-4 font-medium flex justify-center py-3`}>Contact</Link>
              </li>
              <li>
                <Link to="/stock"  className={`${isActive('/stock') ? 'underline' : ''} hover:underline decoration-cyan-950 decoration-2 underline-offset-4 font-medium flex justify-center py-3`}>Stock</Link>
              </li>
              <li>
                <Link to="/work" className={`${isActive('/work') ? 'underline' : ''} hover:underline decoration-cyan-950 decoration-2 underline-offset-4 font-medium flex justify-center py-3`}>Work</Link>
              </li>
            </ul>
          )}

        </nav>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';

function HomePage() {
  return (
    <div className="text-center">
      <Helmet>
        <title>Home - MK</title>
      </Helmet>
      <Header />
        <div className="h-screen bg-cyan-50 flex flex-col items-center justify-start text-cyan-950 pt-28">
          <img src="/wip.png" className="h-36" alt="logo" />
          <p className='pt-7 font-semibold'>Deprecated. New site below.</p>
          <a className='my-4 px-5 py-2 font-medium border-2 border-cyan-700/50 rounded-md' href='https://next.webv.uk'>next.webv.uk</a>
          <a
            className="text-blue-800 font-bold underline decoration-2 text-sm"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </div>
    </div>
  );
}

export default HomePage;

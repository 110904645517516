import React from 'react';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';

function IsandcoPage() {
  return (
    <div>
      <Helmet>
        <title>I.S.&CO - MK</title>
      </Helmet>
      <Header />
      <div className="bg-cyan-50 flex justify-center text-cyan-950">
        <p className='pt-7 pb-4 font-semibold'>Work</p>
      </div>
      <iframe
        src="https://www.isandco.uk"
        title="Embedded Website"
        className="h-[800px] w-full p-10 overflow-clip max-w-screen-xl mx-auto"
      ></iframe>
    </div>
  );
}

export default IsandcoPage;